<template>
  <b-overlay :show="loading">
    <b-row>
      <b-col cols="12">
        <b-card style="overflow: auto">
          <b-form>
            <b-row>
              <b-col md="6">
                <b-form-group label="Judul" label-for="name">
                  <b-form-input id="name" v-model="form.name" />
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group label="Kategori Paket" label-for="paket">
                  <b-form-input
                    id="paket_id"
                    v-model="nama_kategori_paket"
                    readonly
                  />
                  <v-select
                    disabled
                    v-model="form.paket_id"
                    label="text"
                    @input="
                      getSubtest();
                      getPaket();
                    "
                    :clearable="true"
                    :options="optpaket_id"
                    hidden
                  />
                  <!-- :reduce="(option) => option.value" -->
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group label="Nama Paket" label-for="paket">
                  <b-form-input id="paket2_id" v-model="nama_paket" readonly />
                  <v-select
                    disabled
                    v-model="form.paket2_id"
                    label="text"
                    :clearable="true"
                    :options="optpaket2_id"
                    hidden
                  />
                  <!-- :reduce="(option) => option.value" -->
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Tanggal & Waktu Mulai"
                  label-for="start_time"
                >
                  <flat-pickr
                    v-model="form.start_time"
                    class="form-control"
                    :config="{
                      enableTime: true,
                      dateFormat: 'Y-m-d H:i:S',
                      time_24hr: true,
                    }"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Tanggal & Waktu Akhir"
                  label-for="end_time"
                >
                  <flat-pickr
                    v-model="form.end_time"
                    class="form-control"
                    :config="{
                      enableTime: true,
                      dateFormat: 'Y-m-d H:i:S',
                      time_24hr: true,
                    }"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Atur Tanggal di masing-masing ujian"
                  label-for="atur"
                >
                  <b-form-group>
                    <b-form-checkbox
                      v-model="form.is_date_in_subject"
                      switch
                      inline
                    />
                  </b-form-group>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <div align="right">
                  <!-- <b-link v-if="form.paket2_id != null"  :to="{path:`/master-admin/paket-tryout/detail/${this.form.paket2_id}`}" replace> -->
                  <b-button
                    v-if="form.paket?.is_contain_subcat == 1"
                    variant="outline-primary"
                    v-b-modal.modal-xl
                    @click="ModalShowSub"
                    class="btn-icon mb-2"
                    >Tambah Ujian yang tersedia</b-button
                  >
                  <!-- </b-link> -->
                  <!-- <feather-icon icon="PlusIcon" /> -->
                  <b-button
                    v-if="form.paket?.is_contain_subcat == 0"
                    variant="primary"
                    v-b-modal.modal-xl
                    @click="ModalShowSub0"
                    class="btn-icon mb-2"
                    >Tambah Ujian yang tersedia</b-button
                  >
                </div>
              </b-col>
              <b-modal
                v-model="ModalSetting"
                id="modal-Setting-sub"
                centered
                size="xl"
                :title="titleModal"
                no-close-on-backdrop
                no-close-on-esc
              >
                <b-overlay :show="loadingModal">
                  <b-row class="mb-2">
                    <b-col
                      sm="12"
                      md="6"
                      class="mb-1"
                      v-if="form.paket?.is_contain_subcat == 1"
                    >
                      <label>Pilih Kategori Subtest</label>
                      <b-form-select
                        v-model="subtest_id"
                        size="sm"
                        placeholder="- Pilih -"
                        :options="optpaketSubtestData"
                        @input="getSub()"
                      >
                      </b-form-select>
                    </b-col>
                    <b-col sm="12" md="6">
                      <section
                        :class="
                          form.paket?.is_contain_subcat == 1
                            ? 'd-flex justify-content-start justify-content-md-end'
                            : 'd-flex justify-content-start'
                        "
                      >
                        <b-form-group
                          label-for="filterInput"
                          label="Cari"
                          class="mb-0"
                        >
                          <b-input-group size="sm">
                            <b-form-input
                              id="filterInput"
                              v-model="filter"
                              type="search"
                              placeholder="Ex: Nama Ujian"
                            />
                            <b-input-group-append>
                              <b-button
                                :disabled="!filter"
                                @click="filter = ''"
                              >
                                Clear
                              </b-button>
                            </b-input-group-append>
                          </b-input-group>
                        </b-form-group>
                      </section>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12">
                      <b-table
                        :responsive="true"
                        striped
                        :fields="
                          form.is_date_in_subject == false ||
                          form.is_date_in_subject == 0
                            ? fields_ujian1
                            : fields_ujian2
                        "
                        :items="calonujiandata"
                        v-model="setting"
                      >
                        <template #cell(index)="data">
                          {{ data.index + 1 }}
                          <!-- {{ data.item.ujian_id }} -->
                        </template>
                        <template #cell(selected)="{ rowSelected }">
                          <template v-if="rowSelected">
                            <i class="feather icon-disc primary" />
                          </template>

                          <template v-else>
                            <i class="feather icon-circle" />
                          </template>
                        </template>

                        <template #cell(check)="data">
                          <b-form-checkbox
                            :value="data.item"
                            v-model="select_pilih_ujian"
                            variant="success"
                          />
                        </template>

                        <template #cell(start_time)="row">
                          <flat-pickr
                            v-model="row.item.start_time"
                            class="form-control"
                            :config="{
                              enableTime: true,
                              dateFormat: 'Y-m-d H:i:S',
                              time_24hr: true,
                            }"
                          />
                        </template>
                        <template #cell(end_time)="data">
                          <flat-pickr
                            v-model="data.item.end_time"
                            class="form-control"
                            :config="{
                              enableTime: true,
                              dateFormat: 'Y-m-d H:i:S',
                              time_24hr: true,
                            }"
                          />
                        </template>

                        <template #cell(timer)="data">
                          <!-- {{ data.item.timer }} -->
                          <b-form-input
                            v-model="data.item.timer"
                            size="sm"
                            placeholder="timer"
                            type="number"
                          />
                        </template>
                        <template #cell(passing_grade)="data">
                          <b-form-input
                            v-model="data.item.passing_grade"
                            size="sm"
                            placeholder="passing_grade"
                            type="number"
                          />
                        </template>
                        <template #cell(sum_soal)="data">
                          <b-form-input
                            size="sm"
                            :value="data.item?.sum_soal"
                            type="number"
                            disabled
                          />
                        </template>
                      </b-table>
                    </b-col>
                  </b-row>
                </b-overlay>

                <template #modal-footer>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="outline-success"
                    @click.prevent="TambahSub"
                  >
                    <feather-icon icon="SaveIcon" class="mr-25" />Tambah Ujian
                    yang ditandai ✅ ke dalam jadwal
                  </b-button>
                </template>
              </b-modal>
              <b-col md="12">
                <b-table
                  small
                  :responsive="true"
                  v-model="ujians"
                  :fields="
                    form.is_date_in_subject == false ||
                    form.is_date_in_subject == 0
                      ? fields
                      : fields2
                  "
                  :items="ujiandata"
                >
                  <!-- A virtual column -->

                  <template #head(index)> # </template>
                  <template #cell(index)="{ index }">
                    {{ index + 1 }}
                  </template>

                  <!-- A custom formatted column -->
                  <template #cell(action)="row">
                    <b-dropdown
                      variant="link"
                      toggle-class="text-decoration-none"
                      no-caret
                    >
                      <template v-slot:button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="text-body align-middle mr-25"
                        />
                      </template>
                      <b-dropdown-item @click="ModalHapus(row.item)">
                        <feather-icon
                          icon="TrashIcon"
                          class="mr-50 cursor-pointer text-danger"
                          size="21"
                        />
                        <span>Hapus</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </template>
                  <template #cell(start_time)="row">
                    <flat-pickr
                      v-model="row.item.start_time"
                      class="form-control"
                      :config="{
                        enableTime: true,
                        dateFormat: 'Y-m-d H:i:S',
                        time_24hr: true,
                      }"
                    />
                    <!-- {{ humanDateTime(data.item.start_time) }} -->
                  </template>
                  <template #cell(end_time)="data">
                    <flat-pickr
                      v-model="data.item.end_time"
                      class="form-control"
                      :config="{
                        enableTime: true,
                        dateFormat: 'Y-m-d H:i:S',
                        time_24hr: true,
                      }"
                    />
                    <!-- {{ humanDateTime(data.item.end_time) }} -->
                  </template>

                  <template #cell(timer)="data">
                    <!-- {{ data.item.timer }} -->
                    <b-form-input
                      v-model="data.item.timer"
                      size="sm"
                      placeholder="timer"
                      type="number"
                    />
                  </template>
                  <template #cell(passing_grade)="data">
                    <!-- {{ data.item.passing_grade }} -->
                    <b-form-input
                      v-model="data.item.passing_grade"
                      size="sm"
                      placeholder="passing_grade"
                      type="number"
                    />
                  </template>
                  <template #cell(sum_soal)="data">
                    <!-- {{ data.item.passing_grade }} -->
                    <b-form-input
                      v-model="data.item.sum_soal"
                      size="sm"
                      placeholder="0"
                      type="number"
                      disabled
                    />
                  </template>
                  <!-- <template #cell(max_quota)="data">
                    {{ formatRupiah(data.item.max_quota) }}
                  </template> -->
                  <!-- <template #cell(action2)="row">
                  <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>
                    <b-dropdown-item @click="ModalUbah(row.item)">
                      <feather-icon icon="Edit2Icon" class="mr-50" />
                      <span>Edit</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="ModalHapus(row.item)">
                      <feather-icon icon="TrashIcon" class="mr-50" />
                      <span>Hapus</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </template> -->
                </b-table>
              </b-col>
              <b-col md="6">
                <b-form-checkbox
                  checked="false"
                  name="check-button"
                  switch
                  inline
                  v-model="form.active"
                >
                  Aktifkan Jadwal (agar bisa diakses siswa) </b-form-checkbox
                ><br />
                <b-form-checkbox
                  class="mt-1 mb-1"
                  checked="false"
                  name="check-button"
                  v-model="form.file_pembahasan"
                  switch
                  inline
                >
                  Pembahasan & Kunci Jawaban
                </b-form-checkbox>
              </b-col>
              <b-col md="12" class="mt-1">
                <b-form-group
                  label="Jadwal diperuntukkan untuk?"
                  label-for="jadwal"
                >
                  <!-- <b-form-group > -->
                  <b-form-radio-group
                    id="radio-group-2"
                    v-model="form.type"
                    name="radio-sub-component"
                  >
                    <b-form-radio value="member">Member</b-form-radio>
                    <b-form-radio value="non-member">Non Member</b-form-radio>
                  </b-form-radio-group>
                  <!-- </b-form-group> -->
                </b-form-group>
              </b-col>
              <!-- <b-col
                md="12"
                v-if="form.is_date_in_subject == false || form.is_date_in_subject == 0"
              >
                <b-form-group class="mt-1" label="Timer (Menit)" label-for="timer">
                  <b-form-input id="timer" v-model="form.timer" />
                </b-form-group>
              </b-col> -->
              <b-col md="12">
                <b-form-group
                  class="mt-1"
                  label="Maksimal Kuota"
                  label-for="Maksimal Kuota"
                >
                  <b-form-input id="max_quota" v-model="form.max_quota" />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group label="" label-for="Maksimal Kuota">
                  <label class="font-weight-bold mt-1"
                    >Upload file format: jpg, png, pdf, doc, mp3, mp4
                    (opsional)</label
                  >
                  <b-form-file
                    id="Thumbnail"
                    v-model="form.file"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    no-drop
                    accept=".jpg, .png, .pdf, .doc, .mp3, .mp4"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>

          <b-button
            variant="success"
            @click.prevent="Tambah"
            class="w-full mt-3"
          >
            Simpan
          </b-button>
          <b-button
            variant="outline-primary"
            @click.prevent="clearForm"
            class="w-full mt-3 ml-2"
          >
            Reset
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import {
  BMedia,
  BDropdown,
  BDropdownItem,
  BOverlay,
  BMediaAside,
  BMediaBody,
  BImg,
  BAlert,
  BLink,
  BTabs,
  BTab,
  BRow,
  BCol,
  BTable,
  BCard,
  BCardText,
  BCardTitle,
  BCardBody,
  BPagination,
  BProgress,
  BBadge,
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormFile,
  BInputGroup,
  BInputGroupAppend,
  BFormRadioGroup,
  BFormRadio,
  BFormTextarea,
  VBTooltip,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
const limitSoal = 5; // limit pembuatan soal
const mb = 1024;

export default {
  mixins: [heightTransition],
  components: {
    BFormRadio,
    vSelect,
    quillEditor,
    BMedia,
    BDropdown,
    BDropdownItem,
    BOverlay,
    BMediaAside,
    BMediaBody,
    BImg,
    BAlert,
    BLink,
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BCardBody,
    BTable,
    BPagination,
    BProgress,
    BBadge,
    BButton,
    BModal,
    VBModal,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormFile,
    BInputGroup,
    BInputGroupAppend,
    BFormRadioGroup,
    BFormTextarea,
    VBTooltip,
    ToastificationContent,
    flatPickr,
  },
  directives: {
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      loadingModal: false,
      filter: null,
      select_pilih_ujian: [],
      setting: null,
      titleModal: null,
      ModalSetting: false,
      isi: null,
      select_ujian: null,
      allSelected: false,
      nama_kategori_paket: null,
      nama_paket: null,
      ujiandata: [],
      calonujiandata: [],
      ujians: [],
      mapels: [],
      optpaket_id: [],
      optpaket2_id: [],
      optujian: [],
      // default data
      form: {},
      fields: [
        { key: "index", label: "#" },
        { key: "ujian_name", label: "Ujian", sortable: true },
        { key: "sum_soal", label: "Jumlah Soal", sortable: true },
        { key: "timer", label: "Timer (Menit)", sortable: true },
        { key: "passing_grade", label: "Passing Grade", sortable: true },
        { key: "action", label: "#" },
      ],
      fields2: [
        { key: "index", label: "#" },
        { key: "ujian_name", label: "Ujian", sortable: true },
        { key: "start_time", label: "Tgl Mulai", sortable: true },
        { key: "end_time", label: "Tgl Berakhir", sortable: true },
        { key: "timer", label: "Timer (Menit)", sortable: true },
        { key: "sum_soal", label: "Soal", sortable: true },
        { key: "passing_grade", label: "Passing Grade", sortable: true },
        { key: "action", label: "#" },
      ],
      fields_ujian1: [
        { key: "index", label: "#" },
        { key: "check", label: "Pilih", sortable: true },
        { key: "nama_ujian", label: "Ujian", sortable: true },
        { key: "sum_soal", label: "Jumlah Soal", sortable: true },
        { key: "timer", label: "Timer (Menit)", sortable: true },
        { key: "passing_grade", label: "Passing Grade", sortable: true },
      ],
      fields_ujian2: [
        { key: "index", label: "#" },
        { key: "check", label: "Pilih", sortable: true },
        { key: "nama_ujian", label: "Ujian", sortable: true },
        { key: "start_time", label: "Tgl Mulai", sortable: true },
        { key: "end_time", label: "Tgl Berakhir", sortable: true },
        { key: "sum_soal", label: "Jumlah Soal", sortable: true },
        { key: "timer", label: "Timer (Menit)", sortable: true },
        { key: "passing_grade", label: "Passing Grade", sortable: true },
      ],
      subtest_id: null,
      optpaketSubtestData: [],
      paketSubtestData: null,
      loading: false,
    };
  },
  watch: {
    "form.paket2_id"(paket) {
      if (paket) {
        this.getSubtest();
      } else {
        this.ujiandata = [];
      }
    },
    filter(keyword) {
      if (keyword.length > 2 || keyword.length == 0) {
        this.getSub();
      }
    },
  },
  computed: {
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    isPaketContainSubcat() {
      if (!this.form) return false;

      return this.form?.paket?.is_contain_subcat == 1 ? true : false;
    },
  },
  methods: {
    ModalShowSub() {
      this.activeAction = "tambah";
      this.titleModal = "Tambah Ujian yang tersedia";
      this.ModalSetting = true;
      this.getSubtest();
    },
    ModalShowSub0() {
      this.activeAction = "tambah";
      this.titleModal = "Tambah Ujian yang tersedia";
      this.ModalSetting = true;
      this.getSub0();
    },
    tutupModal() {
      this.ModalSetting = false;
    },
    selectAll(checked) {
      this.select_ujian = checked ? this.ujiandata.slice() : [];
    },
    async Tambah() {
      if (this.form.paket_id == null || this.form.paket_id == "") {
        this.pesanGagal();
        return false;
      }
      const fd = new FormData();
      fd.append(`id`, this.$route.params.id);
      fd.append(`paket_id`, this.form.paket2_id);
      fd.append(`name`, this.form.name);
      fd.append(`max_quota`, this.form.max_quota);
      fd.append(
        `is_date_in_subject`,
        this.form.is_date_in_subject == true ? 1 : 0
      );
      fd.append(`active`, this.form.active == true ? 1 : 0);
      fd.append(`file_pembahasan`, this.form.file_pembahasan == true ? 1 : 0);
      fd.append(`type`, this.form.type);
      fd.append(`start_time`, this.form.start_time);
      fd.append(`end_time`, this.form.end_time);
      fd.append(`file`, this.form.file);

      var payload = {
        id: this.$route.params.id,
        paket_id: this.form.paket2_id,
        name: this.form.name,
        max_quota: this.form.max_quota,
        is_date_in_subject: this.form.is_date_in_subject == true ? 1 : 0,
        active: this.form.active == true ? 1 : 0,
        file_pembahasan: this.form.file_pembahasan == true ? 1 : 0,
        type: this.form.type,
        start_time: this.form.start_time,
        end_time: this.form.end_time,
      };

      if (this.id) {
        payload.id = this.id;
      }

      this.loading = true;
      this.$store
        .dispatch("jadwaladmin/save", fd)
        .then(async (jadwal) => {
          const jadwalId = jadwal.id;
          const payloadUjian = [];
          this.ujians.forEach((ujian) => {
            let jadwalData = {
              id: ujian.id,
              jadwal_id: jadwal.id,
              ujian_id: ujian.ujian_id,
              timer: ujian.timer == null ? 0 : ujian.timer,
              start_time: jadwal.start_time,
              end_time: jadwal.end_time,
              sum_soal: ujian.sum_soal == null ? 0 : ujian.sum_soal,
              passing_grade:
                ujian.passing_grade == null ? 0 : ujian.passing_grade,
            };

            if (jadwal.is_date_in_subject == 1) {
              jadwalData.start_time = ujian.start_time;
              jadwalData.end_time = ujian.end_time;
            }

            payloadUjian.push(jadwalData);
          });

          await this.$store.dispatch("jadwaladmin/saveujian", payloadUjian);
          this.loading = false;
          this.displaySuccess({
            message: "Jadwal berhasil diperbaharui",
          });
          this.getUjian();
          this.getDataById();
        })
        .catch((e) => {
          this.loading = false;
          this.displayError(e);
          return false;
        });
    },
    checkQuery() {
      if (this.$route.query.s == undefined) {
        this.$router.push({ path: "/soal" });
      }
    },
    handleOptionFile(e, id) {
      // add file dynamic option to data
      let objFile = e.target.files[0];
      this.dataSoal[id.soal].dataOptions[id.opsi].file = objFile;

      if (
        ["image/jpeg", "image/png", "image/jpg", "image/gif"].includes(
          objFile.type
        )
      ) {
        this.dataSoal[id.soal].dataOptions[id.opsi].file =
          URL.createObjectURL(objFile);
      } else {
        this.dataSoal[id.soal].dataOptions[id.opsi].file = null;
      }
    },
    handleFile(ind, e) {
      // add soal file to data
      let objFile = e.target.files[0];
      this.dataSoal[ind].file = objFile;
      if (
        ["image/jpeg", "image/png", "image/jpg", "image/gif"].includes(
          objFile.type
        )
      ) {
        this.dataSoal[ind].preview_file = URL.createObjectURL(objFile);
      } else {
        this.dataSoal[ind].preview_file = null;
      }
    },
    unhandleFile(ind, e) {
      // remove soal file
      this.dataSoal[ind].file = null;
      this.dataSoal[ind].preview_file = null;
      e.target.value = null;
    },
    unhandleFileOption(e, id) {
      // remove file dynamic option
      this.dataSoal[id.soal].dataOptions[id.opsi].file = null;
      this.dataSoal[id.soal].dataOptions[id.opsi].preview_file = null;
      e.target.value = null;
    },
    resetHandleFile(ind, e) {
      this.$refs["file_" + ind].value = "";
    },
    resetHandleOptionFile(e, id_file_input) {
      this.$refs[id_file_input].value = "";
    },
    async getMyMapel() {
      const params = {
        guru_id: this.profile.id,
      };
      const mapels = await this.$store.dispatch("mapel/getMyMapel", params);
      mapels.map((item) => {
        if (form.mapel) {
          this.mapels.push(form.mapel);
        }
      });
    },
    doFormatRupiah() {
      this.banksoalDataId.price = this.formatRupiah(this.banksoalDataId.price);
      this.banksoalDataId.max_quota = this.formatRupiah(
        this.banksoalDataId.max_quota
      );
    },
    onFiltered(filteredUjians) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredUjians.length;
      this.currentPage = 1;
    },
    ModalUpload() {
      this.id = this.$route.params.id;
      this.activeAction = "upload";
      this.doFormatRupiah();
      this.titleModal = "Upload Thumbnail";
      this.ModalUploadThumbnail = true;
    },
    ModalShow() {
      this.id = null;
      this.activeAction = "tambah";
      this.doFormatRupiah();
      this.titleModal = "Tambah Paket";
      this.Modal = true;
      // this.ModalEdit = false;
    },
    ModalUbah(item) {
      this.id = form.id;
      this.banksoalDataId = item;
      this.activeAction = "ubah";
      this.doFormatRupiah();
      this.titleModal = "Ubah Paket";
      this.Modal = true;
      // this.ModalEdit = true;
    },
    ModalHapus(data) {
      this.$swal({
        title: "Anda Yakin?",
        text: "Data tidak bisa dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Hapus!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          data.fungsi = 1; //soft delete
          this.$store
            .dispatch("jadwaladmin/saveujian", [data])
            .then(() => {
              this.getDataById();
              this.getUjian();
              this.displaySuccess({
                text: "Data berhasil dihapus",
              });
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
          this.$swal({
            icon: "success",
            title: "Terhapus!",
            text: "Data berhasil dihapus.",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Batal",
            text: "Data aman, kamu batal menghapus data :)",
            icon: "error",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        }
      });
    },
    pesanGagal() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Gagal menyimpan data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    async getDataById() {
      this.loading = true;
      const payload = {
        // sekolah_id: localStorage.getItem("sekolah"),
        // filter: this.filter !== null ? this.filter.id : "name",
        // search: this.search,
        // start: (this.currentPage - 1) * this.itemsPerPage,
        // length: this.itemsPerPage,
        // paket_id: this.filterPaket != null ? this.filterPaket.id : null,
        // ujian_id: this.filterUjian != null ? this.filterUjian.id : null,
      };
      this.$store
        .dispatch("jadwaladmin/indexId", this.$route.params.id)
        .then((response) => {
          this.loading = false;
          let form = response.data;
          this.form = form;
          this.form.paket_id = form.paket.category_id;
          // this.form.paket2_id.text = form.paket.name;
          this.form.is_date_in_subject =
            form.is_date_in_subject == 1
              ? (form.is_date_in_subject = true)
              : (form.is_date_in_subject = false);
          this.form.active =
            form.active == 1 ? (form.active = true) : (form.active = false);
          this.form.file_pembahasan =
            form.file_pembahasan == 1
              ? (form.file_pembahasan = true)
              : (form.file_pembahasan = false);
          // this.form.paket2_id = form.paket.name;
          this.form.paket2_id = form.paket.id;
          this.nama_paket = form.paket.name;
          this.nama_kategori_paket = form.paket.category.name;
          // getPaket();
        })
        .catch((error) => {
          this.loading = false;
          this.$root.$emit("errorData", error);
        });
    },
    async getUjian() {
      this.loading = true;
      const payload2 = {
        jadwal_id: this.$route.params.id,
      };
      this.$store
        .dispatch("jadwaladmin/indexujian", payload2)
        .then((response) => {
          this.loading = false;
          let ok = response.data.data;
          this.ujiandata = ok;
        })
        .catch((error) => {
          this.loading = false;
          this.$root.$emit("errorData", error);
        });
    },
    getPaketTryout() {
      const payload = {
        // sekolah_id: localStorage.getItem("sekolah"),
        // filter: this.filter !== null ? this.filter.id : "name",
        // search: this.search,
        // start: (this.currentPage - 1) * this.ujiansPerPage,
        // length: this.ujiansPerPage,
      };
      this.$store
        .dispatch("paketTryout/indexKategoriPaket", payload)
        .then((response) => {
          let paket_id = response.data.data;
          paket_id.map((item) => {
            item.value = item.id;
            item.text = item.name;
          });
          this.optpaket_id = paket_id;
        })
        .catch((error) => {
          this.$root.$emit("errorData", error);
        })
        .finally(() => {
          this.selected = [];
        });
    },
    async getSubtest() {
      this.loading = true;
      const params = {
        paket_id: this.form.paket2_id,
      };
      this.$store
        .dispatch("adminujian/subtesPaket", params)
        .then((response) => {
          let cek = response.data.data;
          cek.map((item) => {
            item.value = item.id;
            item.text = item.name;
          });
          this.optpaketSubtestData = cek;

          this.loading = false;
        })
        .catch((error) => {
          this.$root.$emit("errorData", error);
        });
    },
    async getSub() {
      const payload = {
        paket_id: this.form.paket2_id,
        subcat_id: this.subtest_id != null ? this.subtest_id : null,
      };
      if (this.filter) {
        payload.search = this.filter;
      }
      this.loadingModal = true;
      this.$store
        .dispatch("adminujian/subtesPaket", payload)
        .then((response) => {
          this.loadingModal = false;
          let payloadUjian = [];
          let totalData = 0;
          if (this.isPaketContainSubcat) {
            let cek = response.data.data[0].items;

            let idSubtest = cek.map((item) => item.ujian_id);
            // filter per ujian di jadwal
            let idSetting = this.ujiandata.map((item) => item.ujian_id);
            var filtered = idSubtest.filter(function (e) {
              return this.indexOf(e) < 0;
            }, idSetting);
            var filterMajor = cek.filter(function (item) {
              return filtered.indexOf(item.ujian_id) !== -1;
            });

            this.paketSubtestData = filterMajor;
            payloadUjian = this.paketSubtestData.map((ujian) => {
              return ujian.ujian_id >= 0
                ? {
                    nama_ujian: ujian.nama_ujian,
                    ujian_id: ujian.ujian_id,
                    paket_id: this.$route.params.id,
                    value: null,
                    sum_soal: ujian.sum_soal,
                    soal_ids: ujian.soal_ids,
                  }
                : ujian;
            });
            totalData = payloadUjian.length;
          } else {
            const { data: ujians, totalRecords } = response.data;
            totalData = totalRecords;

            payloadUjian = ujians.map((ujian) => ({
              nama_ujian: ujian.nama_ujian,
              ujian_id: ujian.ujian_id,
              paket_id: this.$route.params.id,
              value: null,
              sum_soal: ujian.sum_soal,
              soal_ids: ujian.soal_ids,
            }));
          }
          this.calonujiandata = payloadUjian;
          this.totalRows3 = totalData;
        })
        .catch((error) => {
          this.loadingModal = false;
          this.displayError(error);
        });
    },
    async getSub0() {
      const payload = {
        // sekolah_id: localStorage.getItem("sekolah"),
        // filter: this.filter !== null ? this.filter.id : "text",
        // search: this.search,
        // start: (this.currentPage - 1) * this.itemsPerPage,
        // length: this.itemsPerPage,
        // paket_id: this.$route.params.id,
        // rumpun_id: this.filterRumpun != null ? this.filterRumpun.value : null,
        paket_id: this.form.paket2_id,
        // subcat_id: this.subtest_id != null ? this.subtest_id : null,
      };
      this.$store
        .dispatch("adminujian/subtesPaket", payload)
        .then((response) => {
          let cek = response.data.data;

          let idSubtest = cek.map((item) => item.ujian_id);
          let idSetting = this.ujiandata.map((item) => item.ujian_id);
          var filtered = idSubtest.filter(function (e) {
            return this.indexOf(e) < 0;
          }, idSetting);
          var filterMajor = cek.filter(function (item) {
            return filtered.indexOf(item.ujian_id) !== -1;
          });

          this.paketSubtestData = filterMajor;
          const payloadUjian = this.paketSubtestData.map((ujian) => {
            return ujian.ujian_id >= 0
              ? {
                  nama_ujian: ujian.nama_ujian,
                  ujian_id: ujian.ujian_id,
                  paket_id: this.$route.params.id,
                  value: null,
                  sum_soal: ujian.sum_soal,
                  soal_ids: ujian.soal_ids,
                }
              : ujian;
          });
          this.calonujiandata = payloadUjian;
          this.totalRows3 = this.calonujiandata.length;
        })
        .catch((error) => {
          this.$root.$emit("errorData", error);
        });
    },
    TambahSub() {
      const payloadUjian = [];
      this.select_pilih_ujian.forEach((ujian) => {
        let jadwalData = {
          jadwal_id: this.$route.params.id,
          ujian_id: ujian.ujian_id,
          timer: ujian.timer == null ? 0 : ujian.timer,
          start_time: this.form.start_time,
          end_time: this.form.end_time,
          sum_soal: ujian.sum_soal,
          passing_grade: ujian.passing_grade == null ? 0 : ujian.passing_grade,
        };
        if (this.form.is_date_in_subject == 1) {
          jadwalData.start_time = ujian.start_time;
          jadwalData.end_time = ujian.end_time;
        }

        payloadUjian.push(jadwalData);
      });

      if (this.id) {
        payload.id = this.id;
      }

      this.loadingModal = true;
      this.$store
        .dispatch("jadwaladmin/saveujian", payloadUjian)
        .then(() => {
          if (this.activeAction == "tambah") {
            this.id = null;
          }
          // this.ModalEdit = false;
          this.subtest_id = null;

          this.ModalSetting = false;
          this.loadingModal = false;
          this.getUjian();
          this.displaySuccess({
            text: "Subtest Paket berhasil di" + this.activeAction,
          });
          this.ModalSub = false;
        })
        .catch((e) => {
          this.loadingModal = false;
          this.displayError(e);
          return false;
        });
    },
  },
  created() {},
  destroyed() {},
  mounted() {
    this.getDataById();
    this.getUjian();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
